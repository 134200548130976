// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Adding IE11 compatibility
import "core-js/stable";
import "regenerator-runtime/runtime";

import Rails from "@rails/ujs"
Rails.start()

import "@hotwired/turbo-rails"

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("stylesheets/application.scss");
require("fonts/icon-collection/icon-collection.font");

// logo
require("assets/banner_placeholder.png");
require("assets/logo--inverted.svg");
require("assets/logo.png");
require("assets/logo.svg");
require("assets/m3_videos/M3-Logo.svg");


// IMPORT HELPERS
import ImportAll from "wp_utils/import_all";
ImportAll('utils', require.context('apps/application/utils/', true, /\.js$/));
